<template>
    <div class="CEESAR-Main">
        <slot />
    </div>
</template>

<script>
export default {
        name: "CEESAR-Main"
}
</script>

<style>
</style>